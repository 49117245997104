import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import { Timer } from 'components/common/Timer/Timer';
import { useFetcher } from 'hooks';
import { useTimer } from 'hooks/useTimer';

import { createPaymentSession } from './actions/createPaymentSession';
import { roundToPrecision } from './helpers';
import { usePaymentContext } from './PaymentContext';
import { PaymentDetails } from './PaymentDetails/PaymentDetails';
import { PaymentLayout } from './PaymentLayout';
import { StripePaymentModule } from './StripePaymentModule';
import { PaymentData } from './types/payment';
import { InitiatePaymentSession, PaymentSystemType, StripePaymentSystem } from './types/paymentSystem';
import { useConfiguration, useGetIsDraft, usePaymentSystemConfiguration } from '../Configuration/hooks';
import { PaymentError } from '../PaymentFeedback/PaymentError';
import { useUpdateIdentifier } from '../QuickPay/hooks';
import { RecurrentInputs } from '../RecurrentInputs/RecurrentInputs';

const SESSION_TIMEOUT_LIMIT = 1000 * 60 * 30; // 30 min

export const PaymentSummary = (): JSX.Element => {
	const [fetchingData, setFetchingData] = useState(false);
	const {
		data: { identifier: paymentId, recurrentPrices },
		config: {
			paymentSystem: { publicKey },
		},
	} = useConfiguration<StripePaymentSystem>();
	const updateIdentifier = useUpdateIdentifier();
	const paymentSystemConfig = usePaymentSystemConfiguration();
	const { initiatePayment, paymentLoading, isPaymentModalOpen, paymentData } = usePaymentContext();
	const { timeLeft, isSessionExpired } = useTimer(SESSION_TIMEOUT_LIMIT);
	const [showRecurrentInputsScreen, setShowRecurrentInputsScreen] = useState(
		recurrentPrices && recurrentPrices.length > 0,
	);
	const isDraft = useGetIsDraft();

	const amount = roundToPrecision(paymentData.amount * 100, 0); // stripe expects 100 for 1.00
	const createPaymentSessionAction = useMemo(() => {
		if (isPaymentModalOpen) {
			return createPaymentSession(
				paymentId,
				{
					session: { type: PaymentSystemType.STRIPE, amount: amount },
					email: paymentData.email,
					address: paymentData.address,
				},
				isDraft,
			);
		}
		// We don't want to recreat the payment session if the amount changes
		// or when the identifier changes, it is already handled this case in the backend
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPaymentModalOpen, paymentData.email, paymentData.address, isDraft]);

	const [initiatedPaymentSession, sessionCreationLoading, sessionCreationError] =
		useFetcher<InitiatePaymentSession | null>(createPaymentSessionAction, null);

	useEffect(() => {
		if (initiatedPaymentSession?.newIdentifier) {
			updateIdentifier(initiatedPaymentSession.newIdentifier);
		}
	}, [initiatedPaymentSession, updateIdentifier]);

	const onPay = (paymentData: PaymentData) => {
		setFetchingData(true);
		setTimeout(() => {
			setFetchingData(false);
			initiatePayment(paymentData);
		}, 900);
	};

	if (isSessionExpired && !isPaymentModalOpen) {
		return (
			<Box
				component="div"
				sx={(theme) => ({
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					overflow: 'auto',
					backgroundColor: theme.palette.background.paper,
					maxWidth: '100%',
				})}
			>
				<PaymentError sessionExpired={isSessionExpired} />
			</Box>
		);
	}

	return (
		<PaymentLayout>
			{paymentSystemConfig.type === PaymentSystemType.STRIPE && <Timer timeLeft={timeLeft} />}
			{showRecurrentInputsScreen ? (
				<RecurrentInputs setShowRecurrentInputsScreen={setShowRecurrentInputsScreen} />
			) : (
				<PaymentDetails onNext={onPay} loading={fetchingData || paymentLoading || sessionCreationLoading} />
			)}
			{publicKey && (
				<StripePaymentModule
					sessionExpired={isSessionExpired}
					timeLeft={timeLeft}
					sessionData={initiatedPaymentSession?.paymentSession || null}
					sessionCreationError={sessionCreationError}
					sessionCreationLoading={sessionCreationLoading}
					publicKey={publicKey}
				/>
			)}
		</PaymentLayout>
	);
};
